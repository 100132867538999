import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import SocialMedia from './routes/SocialMedia';
import Contact from './routes/Contact';
import BlogPost from './routes/BlogPost';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route 
        path='/' 
        element={<App />} 
      >
        <Route path="BlogPost" element={<BlogPost />} />
      </Route>
      <Route 
        path='/SocialMedia' 
        element={<SocialMedia />} 
      />  
      <Route 
        path='/Contact' 
        element={<Contact />} 
      />  
    </Routes>
  </BrowserRouter>
);