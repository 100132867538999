import { Link } from "react-router-dom";

type Props = {
  data: string[]
}

function MessageList({ data }: Props): JSX.Element {
  return (
    <>
      <ul>
        {data.map(message => <li key={message}>
          <Link to="BlogPost">{message}</Link>   
        </li>)}
      </ul>
    </>
  )
}

export default MessageList;
