import NavigationBar from '../components/NavigationBar/NavigationBar'

function Contact(): JSX.Element {
  return (
    <>
    Contact
    <NavigationBar />
    </>
  )
}

export default Contact;
