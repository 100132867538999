import NavigationBar from '../components/NavigationBar/NavigationBar';

function SocialMedia(): JSX.Element {
  return (
    <>
    Social Media
    <NavigationBar />
    </>
  )
}

export default SocialMedia;
