import React, { useState } from 'react';

type Props = {
  onSend: Function
}

function NewMessageForm({ onSend }: Props): JSX.Element {
  const [inputText, setInputText] = useState('');

  function handleTextChange(event: React.FormEvent<HTMLInputElement>): void {   
    setInputText(event.currentTarget.value);
  }

  function handleSend(): void {
    onSend(inputText);
    setInputText('');
  }

  return (
    <>
      <input
        type="text"
        data-testid="messageText" 
        value={inputText}
        onChange={handleTextChange}
      />
      <button
        data-testid="sendButton"
        onClick={handleSend}
      >
        Send
      </button>
    </>
  );
}

export default NewMessageForm;
