import React, { useState } from 'react';
import MessageList from './components/MessageList/MessageList';
import NavigationBar from './components/NavigationBar/NavigationBar';
import NewMessageForm from './components/NewMessageForm/NewMessageForm';

function App(): JSX.Element {
  const [messages, setMessages] = useState<string[]>([]);

  function handleSend(newMessage: string): void  {
    setMessages([newMessage,...messages])
  }

  return (
    <div className="App"> 
      <div className="headeri">
        <NavigationBar />
      </div>
      <h1>List</h1>
      <NewMessageForm 
        onSend={ handleSend }
      />
      <MessageList 
        data={ messages } 
      />
    </div>
  );
}

export default App;
